import React, { useState } from 'react';
import '../styles/SignUp.css';

export default function SignUp() {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Client-side email validation
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      // Simulating a POST request to subscribe the user
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      // Simulated response indicating success
      // Replace this with actual response handling
      if (!response.ok) {
        throw new Error('Failed to subscribe');
      }

      // Simulated success
      setSubscribed(true);
    } catch (error) {
      setError(error.message || 'Subscription failed. Please try again later.');
      console.error('Error subscribing:', error);
    }
  };

  // Function to validate email format
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div className="signup-container">
      {!subscribed ? (
        <form onSubmit={handleSubmit} className="signup-form">
          <label className="form-label">
            Email:
            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="form-input"
            />
          </label>
          <button type='submit' className="form-button">Subscribe</button>
          {error && <p className='error'>{error}</p>}
        </form>
      ) : (
        <p className="success-message">Thank you for subscribing!</p>
      )}
    </div>
  );
}
