import React, { useState } from "react";
import "../App.css";
import "./styles/ScreenSection.css";

function ScreenSection() {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };

  return (
    <div className="screen-container">
      {!videoLoaded && <img src="images/backup.png" alt="Placeholder" />}
      <video
        src="videos/screensaver.mp4"
        autoPlay
        loop
        muted
        playsInline
        onLoadedData={handleVideoLoaded}
        style={{ display: videoLoaded ? "block" : "none" }}
      />
      <h1>Unleash Your Potential</h1>
      <p>A pioneering personal development program</p>
    </div>
  );
}

export default ScreenSection;
