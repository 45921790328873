import React from "react";
import "./styles/Cards.css";
import CardItem from "./CardItem";

function Cards() {
  return (
    <div className="cards">
      <h1>Personality Exchange Program</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/toolkit.png"
              text="A meticulously curated personality exchange program designed for transformative life experiences"
              label="About PEP"
              alt="about"
              path="/about"
            />
            <CardItem
              src="images/Event3.svg"
              text="Discover your strength to overcome challenges and enjoy a life of limitless possibilities"
              label="14:00h, Schelmenwasenstraße 34 70567"
              path="https://forms.gle/rJHyMP731cp6k22u9"
            />
            {/* </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/img-3.jpg'
              text='Set Sail in the Atlantic Ocean visiting Uncharted Waters'
              label='Mystery'
              path='/services'
            />
            <CardItem
              src='images/img-4.jpg'
              text='Experience Football on Top of the Himilayan Mountains'
              label='Adventure'
              path='/products'
            />
            <CardItem
              src='images/img-8.jpg'
              text='Ride through the Sahara Desert on a guided camel tour'
              label='Adrenaline'
              path='/sign-up'
            /> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
