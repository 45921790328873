import React from 'react'
import '../../App.css';
import ScreenSection from '../ScreenSection';
import Footer from '../Footer';
import Cards from '../Cards';



function Home() {
  return (
    <>
    <ScreenSection />
    <Cards />
      <Footer />
    </>
  )
};

export default Home;
