import React from 'react';
import '../../App.css';
import AboutPage from '../PageComponents/AboutPage';
import Footer from '../Footer';

function About() {
  return (
    <>
    <AboutPage />
    <Footer />

    </>
  )
};

export default About;