import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/GlobalStyle.css";
import "../styles/Cards.css";

function AboutPage() {
  return (
    <div className="container-fluid overflow-hidden">
      <div className="card">
        <div className="row">
          <h1 className="heading">Personality Exchange Program</h1>
        </div>
        <div className="row">
          <div className="col-md-4 fade-img">
            <img
              className="img-fluid rounded-start"
              src="images/toolkit.png"
              alt="..."
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h1>PEP is a toolkit of foundational concepts that</h1>
              <ul>
                <li className="mb-3">Creates awareness and consciousness</li>
                <li className="mb-3">
                  Fosters self-understanding through personal knowledge,
                  experiences, and beliefs.
                </li>
                <li className="mb-3">
                  Empower individuals with the skills to harmonize and adjust
                  fundamental concepts.
                </li>
                <li className="mb-3">
                  Ignite the transformation of potential into reality.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="row">
          <div className="col-md-4 fade-img">
            <img
              className="img-fluid rounded-start"
              src="images/Vision.png"
              alt="..."
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <p class="card-text">
                To be the dependable bridge that charts the way from potential
                to manifestation by unleashing full potentials, cultivating
                self-confidence and self-awareness.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="row">
          <div className="col-md-4 fade-img">
            <img
              className="img-fluid rounded-start"
              src="images/Mission.png"
              alt="..."
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <p class="card-text">
                To be the Michelin Star personal development program in the
                world transforming millions of lives annually through
                partnerships, trainings and conferences.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="row">
          <div className="col-md-8">
            <div className="card-body">
              <h1>Why PEP</h1>
              <p class="card-text">
                We are the <span>FERTILE SOIL</span> that transforms seeds to a
                bumper harvest, the FACTORY that transforms raw materials into
                finished products.
              </p>
              <p>
                At PEP, we elevate your awareness and consciousness by exploring
                foundational concepts, and we align your knowledge, experiences,
                and beliefs to help you manifest your full potential.
              </p>
            </div>
          </div>
          <div className="col-md-4 fade-img">
            <img
              className="img-fluid rounded-start"
              src="images/Fertile.png"
              alt="..."
            />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="row">
          <div className="col-md-4 fade-img">
            <img
              className="img-fluid rounded-start"
              src="images/Benefit.png"
              alt="..."
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h1>Benefits of PEP</h1>
              <p class="card-text">
                We expose you to foundational concepts that propels you to
                manifestation and equip you with practical tools needed to align
                your thoughts, actions and beliefs to foundational concepts.
              </p>
              <p>
                We provide continuous support through our structured program,
                team and community.
              </p>
              <p>
                We inspire you to endure the discomfort of change to make
                adjustments that transform your potential to manifestation.
              </p>
              <p>Your manifestation is our SUCCESS.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <h1 className="heading">Our strategy</h1>
      </div>

      <div className="card">
        <div className="row">
          <div className="col-md-4 fade-img">
            <img
              className="img-fluid rounded-start"
              src="images/holistic.svg"
              alt="..."
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <p>
                As gravity stands as a fundamental force governing the natural
                world, demanding precise comprehension and handling, PEP offers
                a comprehensive framework rooted in the very essence of nature.
                Within this paradigm, we integrate foundational principles that
                embrace the multifaceted dimensions of existence, spanning
                career, relationships, health, and mindfulness. Our foundational
                concepts addresses various aspects of life, includingcareer,
                relationships, health, and mindfulness, ensuring a
                well-roundedand fulfilled life.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="row">
          <div className="col-md-4 fade-img">
            <img
              className="img-fluid rounded-start"
              src="images/speakers.svg"
              alt="..."
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <p class="card-text">
                Our carefully selected speakers have each lived and experienced
                at least one tested and proven foundational concept that
                consistently deliver stated outcome.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="row">
          <div className="col-md-4 fade-img">
            <img
              className="img-fluid rounded-start"
              src="images/workshop.svg"
              alt="..."
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <p class="card-text">
                Hands-On workshops designed to enhance your skills and provides
                a practical toolkit for immediate adaption and implementation.
                This leaves you with confidence to start your journey to
                manifestation. Our workshops empower you to take charge of your
                personal and professional life and positions you for impactful
                living.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="row">
          <div className="col-md-4 fade-img">
            <img
              className="img-fluid rounded-start"
              src="images/community.svg"
              alt="..."
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <p class="card-text">
                Connect with like-minded individuals and enjoy a supportive
                network that will encourage your journey to self-mastery and
                manifestation. Share and tap into each others experiences,
                exchange ideas, and forge valuable connections that will last a
                life time.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <h1 className="heading">Expectation from PEP</h1>
      </div>

      <div className="row">
        <div className="col-md-6 col-lg-4">
          <div className="card color1">
            <div className="card-body">
              <p class="nice-text">
                It harnesses people's strengths and overcomes limitations to
                unlock their full potential through an exchange of experiences.
                Using a comprehensive approach, PEP addresses fundamental
                concepts and navigates between the current self and mastery,
                paving the way for holistic growth and achievement.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-4">
          <div className="card color2">
            <div className="card-body">
              <p class="nice-text">
                PEP emerged from more than three decades of tested methodologies
                applied in various institutions across different sectors. It has
                positively influenced thousands of individuals in Europe,
                America, and Africa. PEP is meticulously designed with a unique
                combination of foundational principles and provides practical
                toolkits that ensure effective adaptation.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-4">
          <div className="card color3">
            <div className="card-body">
              <p class="nice-text">
                Aligned with individual goals and aspirations, PEP consistently
                demonstrates a significant impact on heightened awareness and
                consciousness, spiritual and physical well-being, value and
                wealth creation, deeper relationships and social connections,
                and overall confidence in pursuing new ambitions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AboutPage;
